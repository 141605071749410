.table {
  table-layout: fixed;
  width: 100%;
  max-width: 300px;
  margin-right: 150px;
}

.table:last-child {
  margin-right: 0;
}

.item-numerable {
  width: 10%;
}

.item-count {
  white-space: nowrap;
  width: 10%;
}

.item-name {
  padding-right: 10px;
  text-align: left;
  width: 50%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.type-top {
  color: var(--success-color);
}

.type-antiTop {
  color: var(--danger-color);
}
