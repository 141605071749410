.name,
.gender-label {
  margin-right: 5px;
}

.button-remove {
  margin-left: 5px;
}

.hide {
  /* Оставим тут мини-бажек: т.к. мы просто сделали кнопку невидимой, при наведении на место, где должна быть кнопка
   будет отображаться запрещающий курсор. Для фикса удалить opacity, заменить на visibility */
  /* visibility: hidden; */
  opacity: 0;
}
