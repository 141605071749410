.full-size {
  height: 100%;
}

.title {
  font-size: 6rem;
}

.button,
.button:not(:first-child) {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.catLogoContainer {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 769px) {
  .header {
    margin-bottom: -1.5rem !important;
  }
}
