.link {
    margin-right: 3px;
}

.title {
    font-size: 20px;
}

.copy-button {
    height: 25px;
}
