:root {
  --link-color: hsl(217, 71%, 53%);
  --info-color: hsl(204, 86%, 53%);
  --success-color: hsl(141, 71%, 48%);

  --grey-light-color: hsl(0, 0%, 71%);
  --grey-lighter-color: hsl(0, 0%, 86%);
}

#root,
html,
body {
  overflow-y: auto;
  height: 100%;
}
