.small {
  width: 64px;
  height: 64px;
}

.large {
  width: 100%;
  width: 128px;
  height: 128px;
}

@media screen and (max-width: 770px) {
  .large {
    width: 100%;
    height: 100%;
    max-width: 64px;
    max-height: 64px;
  }
}
