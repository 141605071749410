.group {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 2px solid #f5f5f5;
}

.groupHeader {
  margin-bottom: 1.5rem;
}

.filter {
  padding-top: 20px;
  padding-bottom: 0;
}

.name {
  max-width: 100%;
}

.name_link {
  overflow: hidden;
  text-overflow: ellipsis;
}

.namesWrapper {
  display: flex;
}

.leftArrow {
  margin-right: 0.5rem;
  cursor: pointer;
}

.rightArrow {
  margin-left: 0.5rem;
  cursor: pointer;
}

.arrowDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}
