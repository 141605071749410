.ajs-message {
  cursor: pointer;
}

.ajs-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #31708f;
}

.ajs-error,
.ajs-success {
  color: #fff;
}
